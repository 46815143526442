import { sendAnalyticSpecialOfferDiscountCancelClick } from 'services/analytics';

import { useCancelSubscription } from 'hooks';

import SpecialOfferModal from './components/SpecialOfferModal';

const SpecialOfferModalContainer = () => {
    const { onCancelSubscription, onUpdateSubscription, cancellationCandidate } = useCancelSubscription();

    const hasOffer = Boolean(
        cancellationCandidate?.reminder ||
            cancellationCandidate?.discounts?.free_month ||
            cancellationCandidate?.discounts?.pause
    );

    if (!cancellationCandidate) {
        return null;
    }

    const cancelSubscriptionHandler = () => {
        sendAnalyticSpecialOfferDiscountCancelClick();

        onCancelSubscription();
    };

    return (
        <SpecialOfferModal
            hideBackBtn={hasOffer}
            externalId={cancellationCandidate?.externalId}
            onCancelSubscription={cancelSubscriptionHandler}
            onSubmitDiscount={onUpdateSubscription}
        />
    );
};

export default SpecialOfferModalContainer;
