import { Text } from 'wikr-core-components';
import { Trans } from 'react-i18next';
import React, { useState } from 'react';

import { MODAL_CONTENT_FULL_SCREEN_STYLES } from 'components/ModalContainers/constants';

import SecondaryDefaultButton from 'components/SecondaryDefaultButton';
import Modal from 'components/Modal';
import HiddenButton from 'components/HiddenButton';
import CancelModalNavBar from 'components/CancelModalNavBar';
import Textarea from './components/Textarea';

import * as S from './styled';

import { getConfig } from './config';

interface PremiumFeedbackModalProps {
    reason: string;
    isSubscriptionWithPremium: boolean;
    onCancelSubscription: () => void;
    onSendFeedback: (value: string) => void;
}

const PremiumFeedbackModal = ({
    reason,
    isSubscriptionWithPremium,
    onSendFeedback,
    onCancelSubscription,
}: PremiumFeedbackModalProps) => {
    const [feedback, setFeedback] = useState('');
    const [isValidate, setIsValidate] = useState(false);
    const [isFocus, setIsFocus] = useState(false);

    const { title, subtitle, placeholder, buttons } = getConfig(reason, isSubscriptionWithPremium);

    const handleSendFeedback = () => {
        setIsValidate(false);

        onSendFeedback(feedback);
    };

    return (
        <Modal isOpen fullscreen style={{ content: MODAL_CONTENT_FULL_SCREEN_STYLES }}>
            <CancelModalNavBar />
            <S.FeedbackContainer dataLocator="feedbackContainer">
                <S.Title>
                    <Text className="obTitle" type="h5" center mb={10}>
                        <Trans i18nKey={title} />
                    </Text>
                    <Text className="obSubtitle" type="medium-text" center mb={24}>
                        <Trans i18nKey={subtitle} components={{ b: <b /> }} />
                    </Text>
                </S.Title>
                <S.FeedbackContent>
                    <Textarea
                        value={feedback}
                        field="feedback"
                        setFieldValue={setFeedback}
                        setIsValidate={setIsValidate}
                        placeholder={placeholder}
                        isFocus={isFocus}
                        setIsFocus={setIsFocus}
                    />
                </S.FeedbackContent>
                <S.Buttons>
                    <SecondaryDefaultButton
                        disabled={!isValidate}
                        text={buttons.sendFeedbackBtnText}
                        onClick={handleSendFeedback}
                        dataLocator="sendFeedbackButton"
                        className="ctaBtn"
                    />
                    <HiddenButton text={buttons.cancelBtnText} onClick={onCancelSubscription} />
                </S.Buttons>
            </S.FeedbackContainer>
        </Modal>
    );
};

export default PremiumFeedbackModal;
