import { ActionEventAction, ElementEventAction, Main } from 'wikr-core-analytics';

// mainTrackers
import {
    PROGRESS_CANCEL_MODAL,
    PROGRESS_FEEDBACK_MODAL,
    PROGRESS_PAUSE_MODAL,
} from 'components/ModalContainers/ProgressModalContainer/components/ProgressModal/constants';

import { ProgressType } from 'components/ModalContainers/ProgressModalContainer/components/ProgressModal/types';

import { customTrackDecorator, trackClick, trackClickCustom, trackEventFixed, trackScreenLoad } from './mainTrackers';

const SPECIAL_OFFER_MODAL = 'cancel_offer_discount';
const SPECIAL_OFFER_MODAL_FINAL = 'cancel_offer_discount_f';
const CHECK_OUR_APPS_MODAL = 'check_our_apps';
const FEATURE_IDEAS_SENT = 'feature_ideas_sent';
const RETURN_OFFER_MODAL = 'cancel_offer_return';
const RETURN_OFFER_MODAL_FINAL = 'cancel_offer_return_f';
const CANCELLATION_PROGRESS_MODAL = 'cancel_progress';
const CANCELLED_SUCCESS = 'subscription__cancelled';

export const sendAnalyticCancelSubscriptionClick = (eventLabel?: string) => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.cancelSubscription,
        eventLabel,
    });
};

export const sendAnalyticSubscriptionCancelTriggerRequest = () => {
    customTrackDecorator('front_cancel_subscription_request');
};

export const sendAnalyticSubscriptionCancelSuccess = () => {
    trackEventFixed(CANCELLED_SUCCESS);
};

export const sendAnalyticSubscriptionCancelError = (eventLabel: string) => {
    // @ts-ignore
    trackEventFixed('subscription_cancel_error', eventLabel);
};

export const sendAnalyticSubscriptionUpdateError = (eventLabel: string) => {
    // @ts-ignore
    trackEventFixed('subscription_update_error', eventLabel);
};

export const sendAnalyticCancelReasonContinueClick = (eventLabel: string) => {
    trackClick({
        screenIdParam: 'cancel_reason',
        eventLabel,
    });
};

export const sendAnalyticSpecialOfferScreenLoad = () => {
    trackScreenLoad(SPECIAL_OFFER_MODAL);
};

export const sendAnalyticReturnOfferScreenLoad = () => {
    trackScreenLoad(RETURN_OFFER_MODAL);
};

export const sendAnalyticReturnOfferFScreenLoad = () => {
    trackScreenLoad(RETURN_OFFER_MODAL_FINAL);
};

export const sendAnalyticCancelOfferFScreenLoad = () => {
    trackScreenLoad(SPECIAL_OFFER_MODAL_FINAL);
    // @ts-ignore
    trackEventFixed('subscription_update_success');
};

export const sendAnalyticSpecialOfferGetDiscountClick = () => {
    trackClick({
        actionEvent: ActionEventAction.continue,
        elementEvent: ElementEventAction.button,
        screenIdParam: SPECIAL_OFFER_MODAL,
    });
};

export const sendAnalyticReturnOfferGetDiscountClick = () => {
    trackClick({
        actionEvent: ActionEventAction.continue,
        elementEvent: ElementEventAction.button,
        screenIdParam: RETURN_OFFER_MODAL,
    });
};

export const sendAnalyticSpecialOfferDiscountCancelClick = () => {
    trackClickCustom(
        {
            event_action: 'button_continue_cancel',
        },
        SPECIAL_OFFER_MODAL
    );
};

export const sendAnalyticSpecialOfferSuccess = (newSubscriptionId: string) => {
    trackScreenLoad(SPECIAL_OFFER_MODAL_FINAL);
    Main.trackNew({
        // @ts-ignore
        eventData: { event: 'success', screenId: SPECIAL_OFFER_MODAL },
        data: {
            event_label: newSubscriptionId,
        },
    });
};

export const sendAnalyticSpecialOfferError = () => {
    Main.trackNew({
        // @ts-ignore
        eventData: { event: 'error', screenId: SPECIAL_OFFER_MODAL },
        data: {
            event_label: 'Subscription plan not changed, try again',
        },
    });
};

export const sendAnalyticProductProposeModalScreenLoad = () => {
    trackScreenLoad(SPECIAL_OFFER_MODAL);
};

export const sendAnalyticCheckOurAppsModalScreenLoad = () => {
    trackScreenLoad(CHECK_OUR_APPS_MODAL);
};

export const sendAnalyticProductProposeModalViewProductsClick = () => {
    trackClick({
        actionEvent: ActionEventAction.continue,
        elementEvent: ElementEventAction.button,
        screenIdParam: SPECIAL_OFFER_MODAL,
    });
};

export const sendAnalyticProductProposeModalCancelCancellationClick = () => {
    trackClick({
        // @ts-ignore
        actionEvent: 'continue_cancel',
        elementEvent: ElementEventAction.button,
        screenIdParam: SPECIAL_OFFER_MODAL,
    });
};

export const sendAnalyticCheckOurAppsModalExpandClick = (eventLabel: string) => {
    trackClick({
        // @ts-ignore
        actionEvent: 'expand',
        elementEvent: ElementEventAction.button,
        screenIdParam: CHECK_OUR_APPS_MODAL,
        eventLabel,
    });
};

export const sendAnalyticCheckOurAppsModalGetAppClick = (eventLabel: string) => {
    trackClick({
        // @ts-ignore
        actionEvent: 'app',
        elementEvent: ElementEventAction.button,
        screenIdParam: CHECK_OUR_APPS_MODAL,
        eventLabel,
    });
};

export const sendAnalyticCheckOurAppsModalCancelCancellationClick = () => {
    trackClick({
        // @ts-ignore
        actionEvent: 'continue_cancel',
        elementEvent: ElementEventAction.button,
        screenIdParam: SPECIAL_OFFER_MODAL,
    });
};

export const sendAnalyticSurveyModalContinueClick = (eventLabel: string) => {
    trackClick({
        // @ts-ignore
        actionEvent: 'continue_cancel',
        elementEvent: ElementEventAction.button,
        screenIdParam: SPECIAL_OFFER_MODAL,
        eventLabel,
    });
};

export const sendAnalyticSurveyThankYouModalCancelClick = () => {
    trackClick({
        // @ts-ignore
        actionEvent: 'cancel_subscription',
        elementEvent: ElementEventAction.button,
        screenIdParam: SPECIAL_OFFER_MODAL,
    });
};

export const sendAnalyticQuestionnaireModalCancelClick = (eventLabel: string | string[]) => {
    trackClick({
        // @ts-ignore
        actionEvent: 'quiz_continue_dissapointed',
        elementEvent: ElementEventAction.button,
        screenIdParam: SPECIAL_OFFER_MODAL,
        eventLabel,
    });
};

export const sendAnalyticQuestionnaireModalCustomCancelClick = (eventLabel: string | string[]) => {
    // @ts-ignore
    trackEventFixed(FEATURE_IDEAS_SENT, eventLabel);
};

export const sendAnalyticShowReturnOfferClick = (eventLabel?: string) => {
    trackClickCustom({
        event_label: eventLabel || 'discount_flow',
        event_action: 'button__show_offer',
    });
};

export const sendAnalyticSubscriptionContactUsClick = (data: Record<string, unknown>) => () => {
    trackEventFixed('subscription__any__click', 'contact_us', data);
};

export const sendAnalyticSubscriptionCancelClick = (data: Record<string, unknown>) => {
    trackEventFixed('subscription__any__click', 'cancel', data);
};

export const sendAnalyticCancelSubscriptionSubmitClick = () => {
    trackClick({
        screenIdParam: 'cancel_subscription',
        eventLabel: 'yes',
    });
};

export const sendAnalyticCancelSubscriptionCancelClick = () => {
    trackClick({
        screenIdParam: 'cancel_subscription',
        eventLabel: 'no',
    });
};

export const sendAnalyticSendFeedbackClick = (eventName: string, eventLabel: string) => {
    trackEventFixed(`${eventName}__any__click`, eventLabel);
};

export const sendAnalyticProgressModalScreenLoad = (type: ProgressType) => {
    switch (type) {
        case PROGRESS_FEEDBACK_MODAL:
            trackScreenLoad(CANCELLATION_PROGRESS_MODAL, { event_label: 'feedback_sent' });
            break;

        case PROGRESS_CANCEL_MODAL:
            trackScreenLoad(CANCELLATION_PROGRESS_MODAL, { event_label: 'subscription_canceled' });
            break;

        case PROGRESS_PAUSE_MODAL:
            trackScreenLoad(CANCELLATION_PROGRESS_MODAL, { event_label: 'pause' });
            break;
    }
};

export const sendAnalyticInterviewBookCallClick = () => {
    trackClick({
        screenIdParam: 'cancel_interview',
        eventLabel: 'book_call',
    });
};

export const sendAnalyticInterviewContinueClick = () => {
    trackClick({
        screenIdParam: 'cancel_interview',
        eventLabel: 'continue',
    });
};
