import { useEffect, useMemo, useState } from 'react';

import { selectSubscriptions } from 'store/subscriptions/selectors';
import { ModalName } from 'store/modals/types';

import {
    PROGRESS_CANCEL_MODAL,
    PROGRESS_FEEDBACK_MODAL,
    PROGRESS_FREE_MONTH_MODAL,
} from './components/ProgressModal/constants';

import { sendAnalyticProgressModalScreenLoad } from 'services/analytics';

import { useModals, useSelector } from 'hooks';

import { ProgressModalContainerPropsType } from './components/ProgressModal/types';
import ProgressModal from './components/ProgressModal';

const ProgressModalContainer = ({ type, onSuccess }: ProgressModalContainerPropsType) => {
    const { openModal } = useModals();

    const { subscriptions } = useSelector(selectSubscriptions);
    const [isLoading, setIsLoading] = useState(true);

    const subscription = useMemo(() => subscriptions[0], [subscriptions]);

    const isSubscriptionCancelled = subscription?.internal_cancel_at;
    const isSubscriptionWithPremium = Boolean(
        subscription?.granted_one_month_free_from || subscription?.granted_one_month_free_to
    );

    useEffect(() => {
        sendAnalyticProgressModalScreenLoad(type);
    }, [type]);

    useEffect(() => {
        if (isSubscriptionCancelled || isSubscriptionWithPremium) {
            if (isLoading) return;

            setTimeout(() => {
                onSuccess && onSuccess();
            }, 1000);
        }
    }, [isSubscriptionCancelled, isSubscriptionWithPremium, isLoading]);

    const onSubmit = () => {
        setTimeout(() => {
            if (type === PROGRESS_CANCEL_MODAL || type === PROGRESS_FREE_MONTH_MODAL) {
                setIsLoading(false);
                if (!isSubscriptionCancelled || !isSubscriptionWithPremium) return;

                onSuccess && onSuccess();
            }

            if (type === PROGRESS_FEEDBACK_MODAL) {
                openModal(ModalName.thankYouModal, {
                    withPremium: false,
                });
            }
        }, 1000);
    };

    return <ProgressModal onSubmit={onSubmit} type={type} />;
};

export default ProgressModalContainer;
