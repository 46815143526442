import React, { Fragment } from 'react';

import { useCancelSubscription } from 'hooks';

import SpecialOfferModalContainer from '../SpecialOfferModalContainer';
import PremiumFeedbackModalContainer from '../PremiumFeedbackModalContainer';

const TooExpensiveModalContainer = () => {
    const { cancellationCandidate } = useCancelSubscription();
    const isDiscount = cancellationCandidate?.isDiscount;

    return (
        <Fragment>
            {isDiscount && <PremiumFeedbackModalContainer />}
            {!isDiscount && <SpecialOfferModalContainer />}
        </Fragment>
    );
};

export default TooExpensiveModalContainer;
