import { Text, Box, Image } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { useState, memo, useEffect } from 'react';

import { MODAL_CONTENT_FULL_SCREEN_STYLES } from 'components/ModalContainers/constants';
import { reasons } from './constants';

import { sendAnalyticCancelReasonContinueClick } from 'services/analytics';

import Modal from 'components/Modal';
import CancelModalNavBar from 'components/CancelModalNavBar';

import * as S from './styled';

import loader from 'assets/img/subscriptionIcons/loader.svg';

import { ICancellationReasonModal } from './types';

const CancellationReasonModal = ({ isLoading, onSubmit, onDiscard, onClose }: ICancellationReasonModal) => {
    const { t } = useTranslation();

    const [reason, setReason] = useState<string>();

    useEffect(() => {
        if (reason) {
            sendAnalyticCancelReasonContinueClick(reason);

            setTimeout(() => onSubmit(reason), 150);
        }
    }, [reason]);

    const handleCancel = (reason: string) => () => {
        setReason(reason);
    };

    return (
        <Modal isOpen onClose={onClose} fullscreen style={{ content: MODAL_CONTENT_FULL_SCREEN_STYLES }}>
            <CancelModalNavBar isFirstModal onDiscard={onDiscard} />
            <S.ReasonContainer dataLocator="cancelSubscriptionModalContainer">
                {isLoading && (
                    <S.LoaderContainer>
                        <Image maxWidth={24} center alt="loader" src={loader} mb={24} isForceVisible />
                    </S.LoaderContainer>
                )}
                <Text
                    className="obTitle"
                    type="h5"
                    center
                    mb={12}
                    text={t('subscription.cancellationReason.whyCancelling')}
                />
                <Text
                    className="lsp-s"
                    type="medium-text"
                    center
                    mb={24}
                    text={t('subscription.cancellationReason.mostImportant')}
                />
                <Box>
                    {reasons.map(({ title, shortKey }) => (
                        <S.ReasonItem
                            key={shortKey}
                            onClick={handleCancel(shortKey)}
                            isSelected={shortKey === reason}
                            data-locator="option"
                        >
                            <Text className="lsp-s" type="medium-text" text={t(title)} />
                        </S.ReasonItem>
                    ))}
                </Box>
            </S.ReasonContainer>
        </Modal>
    );
};

export default memo(CancellationReasonModal);
