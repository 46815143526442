import { AnalyticUtils } from 'wikr-core-analytics';
import axios, { AxiosInstance, Method } from 'axios';

import config from 'config';

import { setToken, setRefreshToken, removeToken, removeWebToken, removeRefreshToken } from 'api/utils/tokenManagement';

import { resetState } from 'store/rootActions';
import { store } from 'store/configureStore';
import { resetAuth } from 'store/auth/actions';

import { DEFAULT_COUNTRY } from 'constants/localization';
import { AUTH_REFRESH_TOKEN_NAME, AUTH_TOKEN_NAME } from 'constants/api';

import { generateQueryParams } from 'helpers/utils';
import { getCookie } from 'helpers/cookie';

import jwt_decode from 'jwt-decode';
import { clearUserDataFromAnalytics } from '../analytics';

const PLATFORM = 'front';
const CLIENT_VERSION = '0.1.0';

export interface RequestOptions<D = never> {
    url: string;
    method: Method;
    data?: Partial<D>;
    params?: Record<string, string>;
    headers?: Record<string, string | boolean>;
}

export default class ApiClientMethods {
    authToken: string;
    apiUrl: string;
    apiVersion: string;
    apiKey: string;
    isSandbox: boolean;
    isPrivate: boolean;
    timezoneOffset: number;

    constructor({ apiUrl = config.API_URL, isSandbox = false, isPrivate = false }) {
        this.authToken = getCookie(AUTH_TOKEN_NAME) || ''; // localStorage.getItem('token') ? (localStorage.getItem('token') as string) : '';
        this.apiUrl = apiUrl;
        this.apiVersion = config.API_VERSION;
        this.apiKey = config.API_KEY;
        this.isSandbox = isSandbox;
        this.isPrivate = isPrivate;
        this.timezoneOffset = Math.abs(new Date().getTimezoneOffset()) * 60;
    }

    async get<ResponseData>(url: string, params?: Record<string, string>, headers?: Record<string, string | boolean>) {
        return this.request<unknown, ResponseData>({
            url,
            params,
            headers,
            method: 'GET',
            data: {},
        });
    }

    async post<RequestData, ResponseData>(url: string, payload: Partial<RequestData> = {}) {
        return this.request<RequestData, ResponseData>({
            url,
            method: 'POST',
            data: payload,
        });
    }

    async patch<RequestData, ResponseData>(url: string, payload: Partial<RequestData> = {}) {
        return this.request<RequestData, ResponseData>({
            url,
            method: 'PATCH',
            data: payload,
        });
    }

    async delete<RequestData>(url: string, payload: Partial<RequestData> = {}) {
        return this.request<RequestData>({
            url,
            method: 'DELETE',
            data: payload,
        });
    }

    setAuthToken(authToken: string) {
        this.authToken = authToken;
    }

    getToken() {
        return getCookie(AUTH_TOKEN_NAME) || '';
    }

    getRefreshToken() {
        return getCookie(AUTH_REFRESH_TOKEN_NAME) || '';
    }

    getLanguage() {
        return localStorage.getItem('language') || 'en';
    }

    getUUID() {
        const uuid = localStorage.getItem('uuid');

        if (!uuid) {
            return AnalyticUtils.getUuid();
        }

        return uuid;
    }

    getCountry() {
        return AnalyticUtils.getCountry() || DEFAULT_COUNTRY;
    }

    setHeaders(axiosInstance: AxiosInstance, token?: string) {
        if (token) {
            axiosInstance.defaults.headers.common['authorization'] = token;
        }
        axiosInstance.defaults.headers.common['timezone-offset'] = this.timezoneOffset;
        axiosInstance.defaults.headers.common['platform'] = PLATFORM;
        axiosInstance.defaults.headers.common['client-version'] = CLIENT_VERSION;
        axiosInstance.defaults.headers.common['locale'] = `${this.getLanguage()}_${this.getCountry()}`;
        axiosInstance.defaults.headers.common['language'] = this.getLanguage();
        axiosInstance.defaults.headers.common['version'] = this.apiVersion;
        axiosInstance.defaults.headers.common['x-api-key'] = this.apiKey;
        axiosInstance.defaults.headers.common['uuid'] = this.getUUID();
    }

    async generateTokens(payload: any) {
        const { refresh_token } = payload;
        const axiosInstance: AxiosInstance = axios.create({
            baseURL: this.apiUrl,
            headers: {
                version: this.apiVersion,
            },
        });

        this.setHeaders(axiosInstance);

        try {
            const response = await axiosInstance.post('/generate-tokens', {
                refresh_token,
            });

            const { headers } = response;

            setToken(headers.access_token);
            setRefreshToken(headers.refresh_token);

            axiosInstance.defaults.headers.common['authorization'] = this.getToken();

            return {
                token: headers.access_token,
                refresh_token: headers.refresh_token,
            };
        } catch (error) {
            const queryParams = generateQueryParams();

            clearUserDataFromAnalytics();
            removeToken();
            removeWebToken();
            removeRefreshToken();

            store.dispatch(resetState());
            store.dispatch(resetAuth());

            if (location.search) history.pushState(null, '', `${location.origin}?${queryParams}`);

            location.reload();
        }
    }

    async request<RequestData, ResponseData = unknown>(
        options: RequestOptions<RequestData>,
        isAuth: boolean = true
    ): Promise<ResponseData | unknown> {
        const tmpOptions = { ...options, url: `/${options.url}` };

        const axiosInstance: AxiosInstance = axios.create({
            baseURL: this.apiUrl,
        });

        const token = this.getToken();

        token && this.setAuthToken(token);

        if (!this.authToken && this.isPrivate) {
            // sentry.logError(new Error('Required token is missing'), SENTRY_AXIOS, ERROR_LEVELS.CRITICAL, {
            //     label: 'Token Required',
            // });

            console.error('Required token is missing');

            return Promise.reject('Token Required');
        }

        this.setHeaders(axiosInstance, token);

        const { common } = axiosInstance.defaults.headers;

        if (!common['version'] || !common['x-api-key'] || !common['language'] || !common['platform']) {
            // sentry.logError(new Error('Required header is missing'), SENTRY_AXIOS, ERROR_LEVELS.CRITICAL, {
            //     ...common,
            // });

            console.error('Required header is missing');

            return Promise.reject('Required header is missing');
        }

        if (!tmpOptions.params) {
            tmpOptions.params = {};
        }

        if (this.authToken && this.isPrivate) {
            tmpOptions.headers = {
                ...tmpOptions.headers,
                token: this.authToken,
            };
        }

        if (this.isSandbox) {
            tmpOptions.headers = {
                ...tmpOptions.headers,
                sandbox: this.isSandbox,
            };
        }

        tmpOptions.headers = {
            ...tmpOptions.headers,
            'Content-Type': 'application/json',
            version: this.apiVersion,
        };

        if (token) {
            const refreshToken = this.getRefreshToken();
            const decodedToken: any = jwt_decode(token);
            const currentDate = Date.now() / 1000;

            // For testing, token will expire after 1 min
            // if (decodedToken.exp - 3540 < currentDate) {
            if (decodedToken.exp < currentDate) {
                const response = await this.generateTokens({ refresh_token: refreshToken });

                if (response?.token) {
                    tmpOptions.headers = {
                        ...tmpOptions.headers,
                        authorization: response.token,
                    };
                }
            }
        }

        try {
            const response = await axiosInstance(tmpOptions);

            if (response.headers.access_token) response.data.token = response.headers.access_token;
            if (response.headers.refresh_token) response.data.refresh_token = response.headers.refresh_token;
            if (response.headers.web_token) response.data.web_token = response.headers.web_token;
            if (response.headers.country) response.data.country = response.headers.country;

            return Promise.resolve(response);
        } catch (error) {
            const status = error?.response?.status;

            if (isAuth && status === 401) {
                clearUserDataFromAnalytics();
                removeToken();
                removeWebToken();
                removeRefreshToken();

                store.dispatch(resetState());
                store.dispatch(resetAuth());

                const queryParams = generateQueryParams();

                if (location.search) history.pushState(null, '', `${location.origin}?${queryParams}`);

                location.reload();
            }

            if (status >= 500) {
                // sentry.logError(error, SENTRY_AXIOS, ERROR_LEVELS.CRITICAL, { ...tmpOptions, ...error?.response });
            }

            console.error('Server Error', error?.response || '');

            return Promise.reject(error?.response || 'Untracked error');
        }
    }
}
