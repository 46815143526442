import { Button, Text, Box, Image } from 'wikr-core-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { UNAUTHORIZED_CHANGE_PASSWORD } from 'store/user/actionTypes';
import { unauthorizedChangePasswordRequest } from 'store/user/actions';
import { createProcessingSelector } from 'store/loading/selectors';

import { PATHS } from 'constants/routerPaths';

import { sendAnalyticPasswordUpdate } from 'services/analytics';

import { useDispatch, useNotify, useSelector } from 'hooks';

import { getParamFromUrl } from 'helpers/utils';
import { isEmpty } from 'helpers/dataStructuresUtils';

import FormikInput from 'components/FormikInput';

import * as S from './styled';

import unauthorizedChangePassword from 'assets/images/unauthorized-change-password.svg';

import { getFields } from './config';

import { ChangePasswordSchema } from './validationSchema';

const initialValues = {
    newPassword: '',
    confirmNewPassword: '',
};

export const UnauthorizedChangePassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { notifySuccess } = useNotify();
    const { t } = useTranslation();

    const isLoading: boolean = useSelector(createProcessingSelector([UNAUTHORIZED_CHANGE_PASSWORD]));

    const { handleSubmit, errors, values, dirty, setFieldTouched, setFieldValue, touched } = useFormik({
        validateOnBlur: false,
        validationSchema: ChangePasswordSchema,
        initialValues,
        onSubmit: () => {
            const token = getParamFromUrl('token') || '';

            dispatch(
                unauthorizedChangePasswordRequest({
                    payload: {
                        token: token,
                        new_password: values.newPassword,
                    },
                    onSuccess: () => {
                        sendAnalyticPasswordUpdate();
                        notifySuccess(t('change.password.success'));
                        navigate(PATHS.LOGIN);
                    },
                    onError: () => {
                        navigate(PATHS.ERROR_PAGE, { state: { path: PATHS.UNAUTHORIZED_CHANGE_PASSWORD } });
                    },
                })
            );
        },
    });

    return (
        <S.Container paddingTop={32} paddingX={16} paddingBottom={80}>
            <S.AuthImage>
                <Image src={unauthorizedChangePassword} alt="reset-password" isForceVisible />
            </S.AuthImage>
            <Text center mb={20} type="h5" text={t('unauthorized.change.password.title')} />
            <form>
                <Box mb={44}>
                    {getFields().map(({ name, label, type, dataLocator }) => (
                        <FormikInput
                            key={name}
                            type={type}
                            field={name}
                            label={label}
                            setFieldTouched={setFieldTouched}
                            initialValues={initialValues}
                            setFieldValue={setFieldValue}
                            touched={touched}
                            values={values}
                            errors={errors}
                            mb={32}
                            dataLocator={dataLocator}
                            placeholder={label}
                        />
                    ))}
                </Box>
                <Button
                    onClick={handleSubmit}
                    isLoading={isLoading}
                    disabled={!dirty || !isEmpty(errors)}
                    text={t('save')}
                    mb={24}
                    dataLocator="savePasswordButton"
                />
            </form>
        </S.Container>
    );
};
