import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { selectSubscriptions } from 'store/subscriptions/selectors';

import { PRODUCT_CODES } from 'constants/product';
import { SUBSCRIPTION_ACTIVE, SUBSCRIPTION_CANCELLED } from '../constants';

import { useSelector } from 'hooks';

import { getSubscriptionTitle } from '../helpers';

import * as S from './styled';

import { ISubscriptionCardCommonProps } from '../types';

import SubscriptionCardWrapper from './SubscriptionCardWrapper';
import SubscriptionCardHeader from './SabscriptionCardHeader';

const ExpiredSubscription = ({
    tabIcon,
    heading,
    subscriptionPeriod,
    subscriptionType,
    onRestoreSubscription,
    productCode,
}: ISubscriptionCardCommonProps) => {
    const { t } = useTranslation();

    const paymentLabel = getSubscriptionTitle(subscriptionPeriod, false);

    const { subscriptions } = useSelector(selectSubscriptions);
    const mainSubscription = subscriptions.find((subscr) => subscr.product_code === PRODUCT_CODES.APP_FULL_ACCESS);
    const isMainSubscriptionActive = mainSubscription?.status === SUBSCRIPTION_ACTIVE;
    const isMainSubscriptionCancelled =
        mainSubscription?.status === SUBSCRIPTION_CANCELLED || Boolean(mainSubscription?.internal_cancel_at);
    const withRestoreBtn = productCode !== PRODUCT_CODES.APP_VIP_SUPPORT;
    const isSecondSubscription = productCode !== PRODUCT_CODES.APP_FULL_ACCESS;
    const isRestoreBtnDisabled = isSecondSubscription && (!isMainSubscriptionActive || isMainSubscriptionCancelled);

    return (
        <SubscriptionCardWrapper>
            <SubscriptionCardHeader icon={tabIcon} heading={heading} isExpired />
            <S.ExpiredCardWrapper>
                <S.InactiveStatus data-locator="subscriptionStatus">
                    <Text type="small-text" color="on-primary" medium text={t('subscription.status.expired')} />
                </S.InactiveStatus>
                <Text
                    type="large-text"
                    medium
                    color="text-secondary"
                    text={t('subscription.period', {
                        count: Number(paymentLabel?.count),
                        context: paymentLabel?.context,
                        subscriptionType,
                    })}
                />
            </S.ExpiredCardWrapper>
            {withRestoreBtn && (
                <>
                    <S.ResumeButton
                        backgroundColor="secondary-default"
                        hoverColor="secondary-hovered"
                        text={t('subscription.text.restore')}
                        onClick={onRestoreSubscription}
                        dataLocator="restoreSubscriptionButton"
                        disabled={isRestoreBtnDisabled}
                        mb={isRestoreBtnDisabled ? 20 : 0}
                    />
                    {isRestoreBtnDisabled && (
                        <S.RestoreHint>
                            <Text text={t('subscription.text.restoreSecondSub.hint')} />
                        </S.RestoreHint>
                    )}
                </>
            )}
        </SubscriptionCardWrapper>
    );
};

export default ExpiredSubscription;
