import { selectSubscriptions } from 'store/subscriptions/selectors';
import { ModalName } from 'store/modals/types';

import { PROGRESS_FEEDBACK_MODAL } from '../ProgressModalContainer/components/ProgressModal/constants';

import { useScreenLoad } from 'services/analytics/useScreenLoad';
import { sendAnalyticSendFeedbackClick } from 'services/analytics';

import { useSelector, useCancelSubscription, useModals, usePauseSubscription } from 'hooks';

import PremiumFeedbackModal from './components/PremiumFeedbackModal';

interface ModalProps {
    reason: string;
}

const PremiumFeedbackModalContainer = () => {
    const { cancellationCandidate } = useSelector(selectSubscriptions);

    const { onCancelSubscription, discardCancellation } = useCancelSubscription();
    const { isSubscriptionPaused, isSubscriptionWithPremium, onGetFreeMonthPause } = usePauseSubscription();
    const isDiscount = cancellationCandidate?.isDiscount;

    const { openModal, modalProps } = useModals();
    const { reason } = modalProps as ModalProps;

    if (!cancellationCandidate) {
        return null;
    }

    const eventName = isSubscriptionWithPremium ? 'cancel_feedback' : 'cancel_feedback_1m_free';

    useScreenLoad(eventName);

    const cancelSubscriptionHandler = () => {
        onCancelSubscription();
    };

    const sendFeedbackHandler = (value: string) => {
        sendAnalyticSendFeedbackClick(eventName, value);

        discardCancellation();

        if (!isSubscriptionWithPremium) {
            onGetFreeMonthPause();
        } else if (isSubscriptionWithPremium || isSubscriptionPaused || isDiscount) {
            openModal(ModalName.progressModal, { type: PROGRESS_FEEDBACK_MODAL });
        }
    };

    return (
        <PremiumFeedbackModal
            reason={reason}
            isSubscriptionWithPremium={isSubscriptionWithPremium}
            onCancelSubscription={cancelSubscriptionHandler}
            onSendFeedback={sendFeedbackHandler}
        />
    );
};

export default PremiumFeedbackModalContainer;
